import { getMenuFiltersLink } from 'helpers/utils/common';
import {
  capitalizeLabel,
  extractPrices,
  getDefaultProductImageUrl,
  getProductUrl,
  variantFormate,
} from 'helpers/utils/product-details';
import { store } from 'redux/store';

/**
 * Determines the default selected weight for a product based on available weights and user-selected filters.
 *
 * This function prioritizes selecting the smallest weight from the user-selected filters if they are available
 * in the product's data. If no filters are applied or no matching weights are found, it falls back to a default
 * weight, typically 3.5g (eighth ounce).
 *
 * @function getDefaultWeightSelect
 *
 * @param {object} productData - The data for the current product, including available weights and pricing.
 * @param {array} variant - An array of product variants, used as a fallback if no weight filters are applied.
 *
 * @returns {string} - The selected weight, either from the filters or the default (3.5g or another available weight).
 *
 */
const getDefaultWeightSelect = (productData, variant) => {
  // Get selected weight filters from the URL
  const urlParams = new URLSearchParams(window.location.search);
  const selectedWeights = urlParams
    .getAll('refinementList[available_weights][]')
    .map((weight) => weight.replace(' ', '_'));

  // Define available weights in the correct order
  const availableWeights = ['half_gram', 'gram', 'two_gram', 'eighth_ounce', 'quarter_ounce', 'half_ounce', 'ounce'];

  // Determine the default weight based on available filters and product data
  if (selectedWeights.length > 0) {
    for (const weight of availableWeights) {
      if (selectedWeights.includes(weight) && productData?.available_weights?.includes(weight.replace('_', ' '))) {
        return weight;
      }
    }
  }

  // Fallback to 3.5g (eighth_ounce) if no filters or smaller weights are available
  return productData?.price_eighth_ounce ? 'eighth_ounce' : variant?.[0]?.name;
};

export const productCardRestructureData = (algolia?: any, weightData?: any, setWeightData?: any) => {
  const host = store.getState().hostReducer.host;
  const isOH = host?.includes('oh');
  const menuLink = getMenuFiltersLink();
  const variant = variantFormate(algolia?.variants);
  const productId = weightData?.value || variant?.[0]?.value;
  const productData = (productId && algolia?.variants_details[productId]) || {};

  // Use the helper function to get the default weight selection
  const defaultWeightSelect = getDefaultWeightSelect(productData, variant);

  const inventory_potencies =
    productData?.inventory_potencies?.find((item) => item?.price_id === (weightData?.name || defaultWeightSelect)) ||
    productData?.inventory_potencies?.[0];

  // FIXME: this function should be near to cart or product card not in the formatting method - GV
  const handleSelectedWeight = (item) => {
    setWeightData(item);
  };
  const { price_id, thc_potency, cbd_potency, tac_potency } = inventory_potencies || {};
  const { price, discounted_price, max_cart_quantity } = extractPrices(price_id, productData) || {};
  const gram = variant?.find((item) => item.name === (weightData?.name || defaultWeightSelect))?.label || '';
  const formatTags = (type: string) => {
    switch (type) {
      case 'vape':
      case 'flower':
      case 'pre-roll':
      case 'extract':
        return `${
          tac_potency || productData?.percent_thca ? `THCa ${tac_potency || productData?.percent_thca}%` : ''
        } ${thc_potency || productData?.percent_thc ? `THC ${thc_potency || productData?.percent_thc}%` : ''} ${
          cbd_potency || productData?.percent_cbd ? `CBD ${cbd_potency || productData?.percent_cbd}%` : ''
        }`;
      case 'edible':
      case 'tincture':
      case 'topical':
        return `${productData?.dosage ?? ''} ${productData?.amount ?? ''}`;
      default:
        return '';
    }
  };
  return {
    image:
      productData?.image_urls?.[0] ||
      getDefaultProductImageUrl(
        productData?.kind === 'flower' ? productData.kind : productData?.type,
        productData?.category,
        productData?.root_subtype,
      ),
    brandName: productData?.brand,
    type: capitalizeLabel(productData?.brand_subtype),
    name: productData?.name,
    gram: productData?.available_weights?.length > 0 ? gram : '',
    price: discounted_price ? String(parseFloat(discounted_price)) : String(parseFloat(price)) || null,
    discountPrice:
      discounted_price && String(parseFloat(discounted_price)) !== String(parseFloat(price))
        ? String(parseFloat(price))
        : null,
    reviews: (!isOH && productData?.review_count) || null,
    averageRating: (!isOH && productData?.aggregate_rating) || null,
    weights: variant || [],
    isShowWeightSelector: productData?.available_weights?.length > 0 || false,
    defaultWeightSelect: String(weightData?.name || defaultWeightSelect) || '',
    handleSelectedWeight: handleSelectedWeight,
    buttonText: variant?.length > 1 ? 'Select weight' : 'Add to cart',
    strainsLabel: capitalizeLabel(productData?.category) || '',
    saleLabel: productData?.special_amount || '',
    thc: formatTags(productData?.kind),
    url: getProductUrl(menuLink, productId, productData?.url_slug),
    kind: productData.kind,
    productId: productId,
    maxCartLimit: max_cart_quantity || productData?.max_cart_quantity,
  };
};

export const getProductCardStrainsLabelVariant = (
  strainsLabel: string,
): 'orange-border' | 'blue-border' | 'purple-border' | 'white-border' => {
  const label = strainsLabel?.toLowerCase() || '';
  const labelVariants = {
    sativa: 'orange-border',
    indica: 'blue-border',
    hybrid: 'purple-border',
  };

  return labelVariants[label] || 'white-border';
};
