import React, { useState } from 'react';
import Image from 'next/image';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { IconButton } from 'components/atoms/buttons/icon-button/icon-button';
import Label from 'components/atoms/labels/text-and-icon';
import RatingReview from 'components/atoms/rating/review';
import { Typography } from 'components/atoms/typography';
import { SegmentEvents } from 'helpers/constants/forms';
import useIntersectionObserver from 'helpers/hooks/use-intersection-observer';
import { ICartItem } from 'helpers/types';
import { TProductSize } from 'helpers/types/organisms/listing/product-details';
import { getLinkTarget } from 'helpers/utils/common';
import { getProductCardStrainsLabelVariant } from 'helpers/utils/product-card';
import ProductCardTypography from '../product-card-typography';
import ProductCardConfirmVertical from './product-card-confirm-vertical';
import {
  CardVerticalBodyBox,
  CardVerticalBodyGramPriceBox,
  CardVerticalBodyPriceBox,
  CardVerticalBodyTitleBox,
  CardVerticalContainers,
  CardVerticalImageBox,
  EditQuantityCountStyle,
} from './styles';

export type TProps = {
  showFavorite?: boolean;
  containersStyle?: string;
  image: string;
  productId?: number;
  name: string;
  brandName: string;
  price?: string;
  order?: number;
  discountPrice?: string;
  type?: string;
  thc?: string;
  kind?: string;
  gram?: string;
  saleLabel?: string;
  newLabel?: string;
  strainsLabel?: string;
  averageRating?: number;
  reviews?: number;
  defaultWeightSelect?: string;
  handleSelectedWeight?: (data: any) => void;
  handleSegmentEvent?: (text?: string) => void;
  isShowWeightSelector?: boolean;
  buttonText?: string;
  position?: number;
  url?: string;
  maxCartLimit?: number;
  filterReviewsLocations?: boolean;
  weights?: TProductSize[];
  existingProducts?: ICartItem[];
  totalQuantity?: number;
  isOH?: string;
  trackViewEvent?: boolean;
};
type THeaderProps = {
  showFavorite: boolean;
  saleLabel: string;
  newLabel: string;
  strainsLabel: string;
};

const renderHeader = ({ showFavorite = false, strainsLabel, newLabel, saleLabel }: THeaderProps) => {
  const strainsLabelVariant = getProductCardStrainsLabelVariant(strainsLabel);

  return (
    <React.Fragment>
      <div className="absolute left-0 top-0 m-4 flex gap-[6px] lg:m-4 lg:gap-2">
        {showFavorite ? (
          <React.Fragment>
            {strainsLabel && <Label variant={strainsLabelVariant}>{strainsLabel}</Label>}
            {saleLabel && <Label variant="resin">{saleLabel}</Label>}
            {newLabel && <Label variant="grey-200">{newLabel}</Label>}
          </React.Fragment>
        ) : (
          strainsLabel && <Label variant={strainsLabelVariant}>{strainsLabel}</Label>
        )}
      </div>
      <div className="absolute right-0 top-0 m-4 flex gap-[6px] lg:m-3 lg:gap-2">
        {showFavorite ? (
          <IconButton button-type="primary" className="!bg-grey-200 p-2" color="white">
            {/* <MdOutlineFavorite size={'1.5em'} /> */}
          </IconButton>
        ) : (
          <React.Fragment>
            {saleLabel && <Label variant="resin">{saleLabel}</Label>}
            {newLabel && <Label variant="grey-200">{newLabel}</Label>}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

const ProductCardVertical = ({
  showFavorite = false,
  containersStyle,
  image,
  name,
  productId,
  brandName,
  discountPrice,
  order,
  price,
  type,
  kind,
  thc,
  strainsLabel,
  saleLabel,
  newLabel,
  gram,
  weights,
  averageRating,
  filterReviewsLocations,
  isOH,
  existingProducts,
  totalQuantity,
  reviews,
  position,
  defaultWeightSelect,
  handleSelectedWeight,
  trackViewEvent = true,
  handleSegmentEvent,
  isShowWeightSelector = true,
  buttonText = 'Add to cart',
  url,
  maxCartLimit = 10,
}: TProps) => {
  const [showConfirmSection, setShowConfirmSection] = useState(false);
  const targetRef = useIntersectionObserver(() => {
    if (trackViewEvent) handleSegmentEvent(SegmentEvents.PRODUCT_VIEWED);
  });

  return (
    <React.Fragment>
      <CardVerticalContainers
        ref={targetRef}
        href={url || '/'}
        target={getLinkTarget(url)}
        data-testid="vertical-containers"
        className={showConfirmSection ? `!hidden ${containersStyle}` : containersStyle}
      >
        <CardVerticalImageBox>
          <Image
            layout="fill"
            alt="product card"
            loader={(options) => options.src}
            src={image}
            className="h-full object-contain lg:rounded-md"
            priority
          />
          {renderHeader({ showFavorite, newLabel, saleLabel, strainsLabel })}
        </CardVerticalImageBox>
        <CardVerticalBodyBox>
          <CardVerticalBodyTitleBox>
            <ProductCardTypography variant="brand" text={brandName} />
            <ProductCardTypography variant="name" text={name} />
            <ProductCardTypography variant="type" text={type} />
            <ProductCardTypography variant="thc" text={thc} />
            {averageRating && !filterReviewsLocations && (
              <div className="flex flex-row items-center gap-2 pt-2">
                <RatingReview score={averageRating} text={reviews ? `${reviews} reviews` : undefined} />
              </div>
            )}
          </CardVerticalBodyTitleBox>
          <CardVerticalBodyGramPriceBox className="product-card-footer">
            <ProductCardTypography variant="gram" text={gram} />
            <CardVerticalBodyPriceBox>
              <ProductCardTypography variant="price" text={price && Number(price)?.toFixed(2)} className="pr-2" />
              <ProductCardTypography
                variant="discount-price"
                text={discountPrice && Number(discountPrice)?.toFixed(2)}
              />
            </CardVerticalBodyPriceBox>
          </CardVerticalBodyGramPriceBox>
          <CommonButton
            onClick={(e) => {
              e.preventDefault();
              if (!isOH && url?.includes('ohio')) {
                window?.open(url, '_blank');
              } else {
                setShowConfirmSection(true);
                handleSegmentEvent(SegmentEvents.PRODUCT_SELECTED);
              }
            }}
            button-type="secondary"
            color="green"
            className="inline-block w-full text-center"
          >
            <Typography variant="body-small-bold">
              {existingProducts && totalQuantity !== 0 ? (
                <div className="flex justify-center text-center">
                  Edit Quantity <EditQuantityCountStyle>{totalQuantity}</EditQuantityCountStyle>
                </div>
              ) : (
                buttonText
              )}
            </Typography>
          </CommonButton>
        </CardVerticalBodyBox>
      </CardVerticalContainers>
      {showConfirmSection && (
        <ProductCardConfirmVertical
          containersStyle={showConfirmSection ? `flex ${containersStyle && containersStyle}` : 'hidden'}
          name={name}
          brandName={brandName}
          image={image}
          price={price}
          strainsLabel={strainsLabel}
          onClose={() => setShowConfirmSection(false)}
          weights={weights}
          defaultWeightSelect={defaultWeightSelect}
          handleSelectedWeight={handleSelectedWeight}
          isShowWeightSelector={isShowWeightSelector}
          productId={productId}
          maxCartLimit={maxCartLimit}
          thc={thc}
          offer={saleLabel}
          order={order}
          discountPrice={discountPrice}
          position={position}
          kind={kind}
        />
      )}
    </React.Fragment>
  );
};

export default ProductCardVertical;
